.ticket-list {
    @include clean-list;
    @include fs-sm;
    margin-bottom: $gutter*2;

    .header-tickets & {
        margin-bottom: 0;
    }

    @media screen and (min-width: $xxl + 1){
        @include fs-md;
    }
}

.one-ticket {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    padding: 1.25em 0;
    border-bottom: 1px solid rgba($white, .6);
    &.not_available{
        color: rgba(255,255,255, .5);
        .one-ticket__icon{
            opacity: 0.5;
        }

    }

    &:last-child {
        border: none;
    }

    a {
        position: absolute;
        display: block;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;

        span {
            display: none;
        }
    }

    .header-tickets & {
        border-bottom: 0;
        border-top: 1px solid rgba($white, .6);
    }
}

.one-ticket__date {
    flex: 1 0 auto;
    display: flex;
    align-items: center;
    margin-right: 1em;

    &:after {
        content: "";
        display: block;
        width: 0;
        height: 0;
        margin-left: 1em;
        border-bottom: .375em solid transparent;
        border-top: .375em solid transparent;
        border-left: .75em solid white;
        opacity: .5;
        @include transition;
    } 
    &.one-ticket__soon-available{
        width:100%;
        order:4;
        &:after{
            display:none;
        }
            
        
    &.event-card__sale-date{
        &:after{
            display: none;
        }
    }

    &--2-lines {
        position: relative;
        flex-direction: column;
        padding-right: 1.5em;

        &:after {
            position: absolute;
            top: .25em;
            right: 0;
        }
    }

    .one-ticket:hover & {
        &:after {
            opacity: 1;
            transform: translate3d(.25em, 0, 0);
        }
    }
    &.one-ticket__soon-available{
        :hover{
            
        }
        cursor: url('../img/unavailable-icon.png'),auto;
    }
}
}

.one-ticket__marquee {
    --gradient-color: $corail; 
    position: relative;
    // flex: 1 1 100%;
    flex: 0 1 100%;
    overflow: hidden;
    order: 3;

    // &:before,
    // &:after {
    //     content: "";
    //     position: absolute;
    //     display: block;
    //     top: 0;
    //     width: 20px;
    //     height: 100%;
    //     background-color: black;
    //     z-index: 10;
    // }

    // &:before {
    //     left: 0;
    //     background: linear-gradient(-90deg, rgba($corail,0) 0%, rgba($corail,1) 100%);

    // }

    // &:after {
    //     right: 0;
    //     background: linear-gradient(90deg, rgba($corail,0) 0%, rgba($corail,1) 100%);
    // }

    span {
        padding: 0 .5em;
    }

    .header-tickets & {
        &:before,
        &:after {
            display: none;
        }
    }
}

.one-ticket__price {
    display: flex;
    flex: 1 0 auto;
    align-items: flex-start;
    justify-content: flex-end;
    margin-left: 1em;

    .header-tickets & {
        margin-left: 1em;
    }
}

.one-ticket__icon {
    flex: 0 0 2em;
    width: 2em;
    max-width: 2em;
    margin-left: 1em;

    @media screen and (min-width: $xxl + 1){
        @include fs-md;
    }
}