.header-tickets {
    position: fixed;
    width: 100%;
    top: 86px;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: $white;
    padding: 0 20px;
    @include fs-sm;
    z-index: 10;
    background-color: var(--current-bg-color);
    @include transition;

    &--is-scrolled {
        transform: translate3d(0, -100%, 0);
    }

    @media screen and (min-width: $lg + 1){
        display: none;
    }
}

.header-tickets__trigger {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--current-bg-color);
    color: $white;
    border: none;
    padding: $gutter 0;
    cursor: pointer;
    border-top: 1px solid rgba($white, .6);

    &:after {
        content: "";
        display: block;
        width: 0;
        height: 0;
        margin-left: 0.5em;
        margin-right: 0;
        border-top: 0.33em solid transparent;
        border-left: 0.66em solid white;
        border-bottom: 0.33em solid transparent;
        transform: rotate(90deg);
    }
}

.header-tickets__list {
    height: 0;
    overflow: hidden;
}