.agenda-cta {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: -100px;

    h4 {
        --wght: 500;
        --wdth: 0;
        font-variation-settings: "wght" var(--wght),"wdth" var(--wdth),"ital" 0;
        @include fs-big;
        text-transform: uppercase;
        line-height: 1;
    }
}