.event-month {

    .filter-bar + .next_request + & {
        padding-top: 100px;

        @media screen and (min-width: $lg + 1){
            padding-top: 0;
        }
    }
}

// .filter-bar + .event-month {
//     padding-top: 2000px;
// }

.event-month__title {
    display: flex;
    align-items: center;
    justify-content: center;
    //width: calc(100% + #{$gutter * 2});
    width: 100%;
    // margin-left: - $gutter;
    margin: 70px 0 0 0;
    margin: 0;
    height: auto;
    //height: 90vh;
    // overflow: hidden;
    @include fs-big;
    line-height: 1;
    text-transform: uppercase;
    overflow: hidden;

    @media screen and (min-width: $lg + 1){
        height: auto;
        min-height: 90vh;
        width: calc(100% + #{$gutter * 4});
        margin-left: - $gutter*2;
    }

    @media screen and (max-height: 980px){
        margin-top: 70px;
    }
}

.event-month__word {
    display: flex;
    align-items: baseline;
    .event-month-year-short{
        font-size: 30rem;

        font-size: clamp(20px,20vw,30rem);
        @media screen and (max-width: $sm){
            font-size:5rem;
        }
    }
}

.event-month__letter {
    --wght: 500;
    --wdth: 0;
    font-variation-settings: "wght" var(--wght),"wdth" var(--wdth),"ital" 0;
}

.event-month__word--desktop {
    --wght: 500;
    --wdth: 0;
    font-variation-settings: "wght" var(--wght),"wdth" var(--wdth),"ital" 0;

    @media screen and (max-width: $sm){
        display: none;
    }
}

.event-month__word--mobile {
    --wght: 500;
    --wdth: 0;
    text-align: center;
    line-height: .75em;
    padding: .25em $gutter*2;
    font-variation-settings: "wght" var(--wght),"wdth" var(--wdth),"ital" 0;
    hyphens: auto;

    @media screen and (min-width: $sm + 1){
        display: none;
    }
}