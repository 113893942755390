.loader-agenda {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: $gutter;
    opacity: 0;
    visibility: hidden;

    svg {
        width: 100%;
        max-width: 75px;
    }

    &.loading {
        opacity: 1;
        visibility: visible;
        @include transition;

        svg {
            animation: load-rotate 1.2s linear infinite;
        }
    }
}

@keyframes load-rotate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

// .loader-agenda__circle {
//     position: relative;
//     width: 50px;
//     height: 50px;
//     border-radius: 5px;
//     background-color: rgba($white, .25);

//     &:after {
//         content: "";
//         position: absolute;
//         display: block;
//         top: 0;
//         left: 0;
//         width: 100%;
//         height: 100%;
//         background-color: $white;
//     }
// }