.screen-404 {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
    //margin-left: -40px;
    // background-image: url(../img/bg-404.svg);
    // background-size: 100% 100%;
    // background-repeat: no-repeat;
}

.screen-404__svg {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
}

.screen-404__messsage {
    background-color: $black;
    color: $white;
    padding: .5em;
    @include fs-xl;
    margin-bottom: $gutter;
}

.screen-404__cta {
  display: flex;
  flex-direction: column;
  padding: 0 $gutter;

  > a {
    &:first-child {
      margin-right: 0;
      margin-bottom: $gutter;
    }
  }

  @media screen and (min-width: $md + 1){
    flex-direction: row;

    > a {
      &:first-child {
        margin-right: $gutter;
        margin-bottom: 0;
      }
    }
  }
}

.flickering {
    animation: flicker ease-in-out forwards;
    animation-iteration-count: infinite;
    animation-delay: 0s;
    animation-duration: 1s;

    &--2 {
        animation-delay: 1s;
        animation-duration: 2s;
    }

    &--3 {
        animation-delay: 2.5s;
        animation-duration: 1.5s;
    }
}

@keyframes flicker {
    0% {
      filter: brightness(1);
    }
    3% {
      filter: brightness(0);
    }
    6% {
      filter: brightness(0);
    }
    7% {
      filter: brightness(1);
    }
    8% {
      filter: brightness(0);
    }
    9% {
      filter: brightness(1);
    }
    10% {
      filter: brightness(0);
    }
    20% {
      filter: brightness(1);
    }
    50% {
      filter: brightness(1);
    }
    99% {
      filter: brightness(0);
    }
    100% {
      filter: brightness(1);
    }
  }