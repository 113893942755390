.manifesto-item {
    display: flex;
    flex-direction: column;
    min-height: 100vh;

    &:first-child {
        margin-top: $gutter*3;
    }

    &:last-child {
        margin-bottom: $gutter*3;
    }

    @media screen and (min-width: $lg + 1) {
        height: unset;
    }
}

.manifesto-item__header {
    --wdth: 0;
    --wght: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    flex: auto;
    @include fs-3xl;
    padding: 1em 0 .75em 0;
    text-align: center;
    text-transform: uppercase;
    line-height: .75;
    font-variation-settings: "wght" var(--wght), "wdth" var(--wdth), "ital" 0;

    h2 {    
        display: none;

        // >div {
        //     position: relative;

        //     &:before {
        //         content: 'A';
        //         position: absolute;
        //         top: 0;
        //         left: 0;
        //         color: $black;
        //     }
        // }
    }

    .manifesto-title-mobile {
        display: block;
    }

    // br {
    //     display: block;

    //     @media screen and (min-width: $lg + 1) {
    //         display: none;
    //     }
    // }
    
    @media screen and (min-width: $lg + 1) {
        height: 90vh;

        h2 {
            display: block;
        }
        
        .manifesto-title-mobile {
            display: none;
        }
    }


}

.manifesto-item__content {}

.manifesto-item__title {
    display: flex;
    justify-content: space-between;
    text-transform: uppercase;
    @include fs-lg;
    margin-bottom: 1em;
}

.manifesto-item__index {}

.manifesto-item__text {}

[data-anim="heartbeat"] {
    >div {
        animation: heartBeat 1s infinite;
    }

    @for $i from 1 through 50 {

        >div:nth-child(#{$i}) {
            
            animation-delay: $i * -.025s;
        }
    }
}

[data-anim="jump"] {
    >div {
        animation: jump 1s steps(5) infinite;
    }

    @for $i from 1 through 50 {

        >div:nth-child(#{$i}) {
            
            animation-delay: -$i * -.025s;
        }
    }
}

[data-anim="curiosity"] {
    >div {
        animation: curiosity 2s infinite cubic-bezier(0.86, 0, 0, 1.02);
    }

    @for $i from 1 through 50 {

        >div:nth-child(#{$i}) {
            
            animation-delay: -$i * -.025s;
        }
    }
}

[data-anim="tada"] {
    >div {
        animation: tada 1s infinite ease-out;
    }

    @for $i from 1 through 50 {

        >div:nth-child(#{$i}) {
            
            animation-delay: -$i * -.025s;
        }
    }
}

[data-anim="jello"] {
    >div {
        animation: jello 1s infinite ease-out;
    }

    @for $i from 1 through 50 {

        >div:nth-child(#{$i}) {
            
            animation-delay: -$i * -.025s;
        }
    }
}

[data-anim="shakex"] {
    >div {
        animation: shakeX 1s infinite ease-out;
    }

    @for $i from 1 through 50 {

        >div:nth-child(#{$i}) {
            
            animation-delay: -$i * -.015s;
        }
    }
}

[data-anim="bounce"] {
    >div {
        animation: bounce 1.5s infinite;
    }

    @for $i from 1 through 50 {

        >div:nth-child(#{$i}) {
            
            animation-delay: -$i * -.025s;
        }
    }
}

@keyframes bounce {
    from,
    20%,
    53%,
    to {
      -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
      animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }
  
    40%,
    43% {
      -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
      animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
      -webkit-transform: translate3d(0, -50%, 0) scaleY(1.1);
      transform: translate3d(0, -50%, 0) scaleY(1.1);
    }
  
    70% {
      -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
      animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
      -webkit-transform: translate3d(0, -25%, 0) scaleY(1.05);
      transform: translate3d(0, -25%, 0) scaleY(1.05);
    }
  
    80% {
      -webkit-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
      transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
      -webkit-transform: translate3d(0, 0, 0) scaleY(0.95);
      transform: translate3d(0, 0, 0) scaleY(0.95);
    }
  
    90% {
      -webkit-transform: translate3d(0, -2%, 0) scaleY(1.02);
      transform: translate3d(0, -2%, 0) scaleY(1.02);
    }
  }

@keyframes jello {
    from,
    11.1%,
    to {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }
  
    22.2% {
      -webkit-transform: skewX(-12.5deg) skewY(-12.5deg);
      transform: skewX(-12.5deg) skewY(-12.5deg);
    }
  
    33.3% {
      -webkit-transform: skewX(6.25deg) skewY(6.25deg);
      transform: skewX(6.25deg) skewY(6.25deg);
    }
  
    44.4% {
      -webkit-transform: skewX(-3.125deg) skewY(-3.125deg);
      transform: skewX(-3.125deg) skewY(-3.125deg);
    }
  
    55.5% {
      -webkit-transform: skewX(1.5625deg) skewY(1.5625deg);
      transform: skewX(1.5625deg) skewY(1.5625deg);
    }
  
    66.6% {
      -webkit-transform: skewX(-0.78125deg) skewY(-0.78125deg);
      transform: skewX(-0.78125deg) skewY(-0.78125deg);
    }
  
    77.7% {
      -webkit-transform: skewX(0.390625deg) skewY(0.390625deg);
      transform: skewX(0.390625deg) skewY(0.390625deg);
    }
  
    88.8% {
      -webkit-transform: skewX(-0.1953125deg) skewY(-0.1953125deg);
      transform: skewX(-0.1953125deg) skewY(-0.1953125deg);
    }
  }

@keyframes var {
    0% {
        font-variation-settings: "wght" 100,"wdth" 0,"ital" 0;
    }

    50% {
        font-variation-settings: "wght" 500,"wdth" 0,"ital" 0;
    }

    100% {
        font-variation-settings: "wght" 100,"wdth" 0,"ital" 0;
    }
}

@keyframes jump {
    0% {
        transform: translate3d(0, -40%, 0);
    }

    50% {
        transform: translate3d(0, 40%, 0);
    }

    100% {
        transform: translate3d(0, -40%, 0);
    }
}

@keyframes curiosity {
    0% {
        transform: scaleY(.25);
    }

    50% {
        transform: scaleY(1);
    }

    100% {
        transform: scaleY(.25);
    }
}

@keyframes tada {
    from {
      -webkit-transform: scale3d(1, 1, 1);
      transform: scale3d(1, 1, 1);
    }
  
    10%,
    20% {
      -webkit-transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
      transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
    }
  
    30%,
    50%,
    70%,
    90% {
      -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
      transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
    }
  
    40%,
    60%,
    80% {
      -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
      transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
    }
  
    to {
      -webkit-transform: scale3d(1, 1, 1);
      transform: scale3d(1, 1, 1);
    }
  }

@keyframes zoom {
    0% {
        transform: rotate(-25deg);
    }

    50% {
        transform: rotate(25deg);
    }

    100% {
        transform: rotate(-25deg);
    }
}


@keyframes shakeX {
    from,
    to {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }
  
    10%,
    30%,
    50%,
    70%,
    90% {
      -webkit-transform: translate3d(-10px, 0, 0);
      transform: translate3d(-10px, 0, 0);
    }
  
    20%,
    40%,
    60%,
    80% {
      -webkit-transform: translate3d(10px, 0, 0);
      transform: translate3d(10px, 0, 0);
    }
  }

@keyframes heartBeat {
    0% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  
    14% {
      -webkit-transform: scale(1.3);
      transform: scale(1.3);
    }
  
    28% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  
    42% {
      -webkit-transform: scale(1.3);
      transform: scale(1.3);
    }
  
    70% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }