.form--subscribe {
    container-type: inline-size;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    

    > * {
        font-size: inherit!important;
    }

    ul {
        @include fs-md;

        @media screen and (min-width: $lg + 1){
            @include fs-lg;
        }
    }
}

.form__main-list {
    display: flex;
    flex-direction: column;
   

    > li {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        

        > div {
            flex: 0 1 100%;
            margin-bottom: $gutter;



            @container (min-width: 700px){
                flex: 0 1 calc(50% - #{$gutter / 2});
            }

            &:only-child {
                flex: 1 1 100%;
            }

        }
    }
}

input {
    background-color: transparent;
    border: none;
    border-bottom: 1px solid $white;
    width: 100%;
    
    padding: 0;
    @include transition;
    transition-duration: .15s;
    
    &::placeholder {
        opacity: .5;
        color: $white;
    }

    .form & {

        &:focus {
            background-color: rgba($white, .25);
            outline: none;
            padding: .25em 10px;
        }
        
    }

} 

[type="text"],
[type="email"],
textarea {
    color: $white;
    padding: .25em 0;
    line-height: 1.15;

    &:focus {
        outline: none;
        background-color: rgba(255, 255, 255, .1);
        padding: .25em .25em;
    }
}

select {
    background-color: transparent;
    color: $white;
    border: none;
    border-bottom: 1px solid $white;
    width: 100%;
    padding: .25em 0;
    line-height: 1.15;
    height: 1.15em;
    height: 100%;
    // box-sizing: content-box;
    appearance: none;
    border-radius: 0;
    @include transition;
    transition-duration: .15s;

    &:focus {
        background-color: rgba($white, .25);
        outline: none;
    }

    option {
        background-color: var(--current-bg-color, $black);
    }
}

label {
    font-size: inherit;
}

.label--is-hidden {
    display: none;
}

.yes-no {

}

.yes-no ul {
    display: flex;
}

.yes-no li {
    display: flex;
    margin-right: $gutter;
    
}

[type="radio"] {
    display: none;

    + label {
        display: flex;
        align-items: center;
        cursor: pointer;

        &:before {
            content: "";
            display: inline-block;
            width: calc(1em - 12px);
            height: calc(1em - 12px);
            padding: 6px;
            border: 2px solid white;
            border-radius: 50%;
            margin-right: .25em;
            background-clip: content-box;
            @include transition;
            transition-duration: .15s;
            cursor: pointer;
        }
    }

    &:checked {
        + label {
            &:before {
                background-color: $white;
            }
        }
    }
}

.form__title {
    @include fs-xl;
    margin: 2em 0 .5em 0;
}

.form__submit {
    width: 100%;
    margin-top: $space-2xl;
}

textarea {
    overflow: auto;
    width: 100%;
    background-color: transparent;
    border: none;
    border-bottom: 1px solid $white;
    resize: none;
    @include transition;

    &::placeholder {
        opacity: .5;
        color: $white;
    }
}

.wpcf7-not-valid-tip {
    display: block;
    font-size: 12px!important;
    font-size: 1.2rem!important;
    padding-top: .5em;
}

.wpcf7 form .wpcf7-response-output {
    border: none!important;
    margin: 1em 0!important;
    text-align: center;
}