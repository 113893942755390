.header {

}

.header--regular {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: auto;
    min-height: 90vh;
    padding-top: 150px;
}

.header--regular__title {
    --wght: 500;
    --wdth: 0;
    @include fs-3xl;
    max-width: 100%;
    hyphens: auto;
    font-variation-settings: "wght" var(--wght),"wdth" var(--wdth),"ital" 0;
    text-transform: uppercase;
    text-align: center;
    line-height: 0.75;
}

.header--regular__sub-title {
    @include fs-md;
    font-variation-settings: "wght" 100,"wdth" 100,"ital" 0;
}

.header--single-event {
    height: 90vh;
    overflow: hidden;
    background-color: $black;
    
    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 120px;
        background: linear-gradient(360deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.5) 100%);
        z-index: 10;
    }
}

.header-carousel {
    width: 100%;
    height: 100%;;
    //height: calc(100% + 20vh);
    //transform: scale(1.1);
}

.header-carousel__item {
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.header-carousel__img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    transform: scale(1.1);
}