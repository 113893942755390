.block--list,
.block--contact-list,
.block--equipe {
    .two-side__content & {
        grid-column: 1 / span 6;
    }
}



.list {
    @include clean-list;
}

.list__item {
    @include fs-md;
    border-top: 1px solid $white;
    border-bottom: 1px solid $white;
    margin-top: -1px;
}

.list__item--area {}

.list__link {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: $white;
    text-decoration: none;
    padding: 1em 0;
    color: $white;
    font-variation-settings: "wght" 500, "wdth" 100,"ital" 0;
    @include transition;

    &:before {
        content: "";
        position: absolute;
        display: block;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: $white;
        // background-image: linear-gradient(
        //     90deg,
        //     hsl(262deg 100% 50%) 0%,
        //     hsl(335deg 100% 36%) 40%,
        //     hsl(55deg 100% 50%) 70%
        //     );
        transform-origin: center left;
        transform: scalex(.25);
        opacity: 0;
        z-index: -1;
        @include transition;
    }

    &:after {
        content: "";
        display: block;
        width: 0;
        height: 0;
        margin-left: .5em;
        border-top: 7.5px solid transparent;
        border-left: 15px solid $white;
        border-bottom: 7.5px solid transparent;
        @include transition;
    }


    span {
        display: flex;
        align-items: center;

        &:before {
            content: "";
            display: inline-block;
            width: 9px;
            height: 9px;
            margin-right: 0.25em;
            border: 1px solid $white;
            border-radius: 50%;
            opacity: 0.5;
            padding: 4px;
            margin-right: 1em;
            background-color: $white;
            background-clip: content-box;
            @include transition;
        }
    }

}

.list__link:hover {
    color: $black;
    padding-right: 1em;
    padding-left: 1em;
    font-variation-settings: "wght" 800, "wdth" 100,"ital" 0;

    &:before {
        opacity: 1;
        transform: scalex(1);
        
        // background-image: linear-gradient(
        //     90deg,
        //     hsl(262deg 100% 100%) 0%,
        //     hsl(335deg 100% 100%) 40%,
        //     hsl(55deg 100% 100%) 70%
        //     );
    }

    &:after {
        border-left: 15px solid $black;
        transform: translate3d(-.5em, 0, 0);
    }

    span {
        &:before {
            border: 1px solid $black;
            background-color: $black;
            margin-right: 1.5em;
            transform: scale(1.1);
            opacity: 1;
        }
    }

}

.contact-list {
    container-type: inline-size;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.team-list {
    container-type: inline-size;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;

}

.member {
    flex: 0 0 100%;
    margin-bottom: $gutter*2;

    a {
        text-decoration: none;
    }

    @container (min-width: 350px){
        flex: 0 0 calc((100% / 2) - #{$gutter / 2});
    }

    @container (min-width: 700px){
        flex: 0 0 calc((100% / 3) - #{$gutter / 3 * 2});
    }
}

.member__img {
    position: relative;
    width: 100%;
    aspect-ratio: .75;
    border-radius: $gutter;
    overflow: hidden;
    margin-bottom: $gutter/2;

    img {
        position: absolute;
        display: block;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
        @include transition;

        a:hover & {
            transform: translate3d(0,0,0) scale(1.05);
        }
    }
}

.member__info {
    display: flex;
    flex-direction: column;
}

.member__role {
    @include fs-md;
}

.member__name {
    @include fs-lg;
    margin: .25em 0;
}

.member__tag {
    @include fs-sm;
}