.two-sides {
    display: flex;
    flex-direction: column;

    @media screen and (min-width: $lg + 1){
        display: grid;
        grid-template-columns: repeat(12, 1fr);
        grid-column-gap: $gutter;
    }
}

.two-sides__side--left {
    grid-area: 1 / 1 / 2 / 6;
}

.two-sides__side--right {
    grid-area: 1 / 6 / 2 / 13;
    // padding-top: 126px;
}

.two-sides__title {
    --wght: 500;
    --wdth: 0;
    position: sticky;
    top: 166px;
    top: 206px;
    left: 0;
    @include fs-3xl;
    margin-bottom: 126px;
    font-variation-settings: "wght" var(--wght),"wdth" var(--wdth),"ital" 0;
    text-transform: uppercase;
    max-width: 100%;
    hyphens: auto;
    line-height: 0.75;
}

.two-side__content {
    display: flex;
    flex-direction: column;

    @media screen and (min-width: $lg + 1){
        display: grid;
        grid-template-columns: repeat(7, 1fr);
        grid-column-gap: $gutter;
        padding-bottom: 126px;
    }
}