.news {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: $black;
    z-index: 100;
    transition: all .3s ease-in-out;

    &--is-closed {
        transform: translate3d(0, 50%, 0);
    }
}

.news__marquee {
    --offset: 20vw;
    --move-initial: calc(-25% + var(--offset));
    --move-final: calc(-50% + var(--offset));
    position: relative;
    overflow: hidden;
    display: block;
    width: 100%;
    border: none;
    background-color: $black;
    color: $white;
    cursor: pointer;
    @include fs-xl;
    padding: .15em 0;
    font-variation-settings: "wght" 600, "wdth" 10, "ital" 0;
    text-transform: uppercase;

    span {
        padding-right: .5em;
    }
}

.news__marquee__inner {
    width: fit-content;
    display: flex;
    position: relative;
    transform: translate3d(var(--move-initial), 0, 0);
    animation: marquee 25s linear infinite;
    animation-play-state: running;
    

    .news--is-closed & {
        animation-play-state: paused;
    }

}

@keyframes marquee {
    0% {
        transform: translate3d(var(--move-initial), 0, 0);
    }

    100% {
        transform: translate3d(var(--move-final), 0, 0);
    }
}

.news__content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background-color: $black;
    // box-shadow: 0px 20px 20px 24px rgba($green,1);
    z-index: -1;
    transform: translate3d(0, -100%, 0);
    transition: all .3s ease-in-out;

    .news--is-closed & {
        transform: translate3d(0, 0, 0);
    }

    .flickity-prev-next-button {
        border: 2px solid $white;
        background: $black;
        width: 30px;
        height: 30px;

        @media screen and (min-width: $lg + 1){
            width: 40px;
            height: 40px;
        }
    }

    .arrow {
        fill: $white;
    }

    .previous {
        top: unset;
        left: unset;
        right: 60px;
        bottom: 0;

        @media screen and (min-width: $lg + 1){
            right: 70px;
        }
    }

    .next {
        top: unset;
        right: $gutter;
        bottom: 0;
    }
}

.close-news {
    position: absolute;
    display: block;
    right: $gutter;
    top: $gutter;
    width: 20px;
    height: 20px;
    padding: 4px;
    background: $black;
    border: none;
    z-index: 10;
    cursor: pointer;

    &__bar {
        position: absolute;
        display: block;
        top: calc(50% - 1px);
        left: 2px;
        width: 100%;
        height: 2px;
        background-color: $white;

        &:first-child {
            transform: rotate(45deg);
        }

        &:last-child {
            transform: rotate(-45deg);
        }
    }
}

.news__slide {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    padding:  $gutter $gutter 0 $gutter;

    @media screen and (min-width: $lg + 1){
        padding:  $gutter*2 $gutter*2 $gutter $gutter*2;
    }

    @media screen and (min-width: $sm + 1){
        flex-direction: row;
    }
}

.news__text {
    flex: 0 0 100%;
    width: 100%;

    @media screen and (min-width: $sm + 1){
        flex: 0 0 50%;
        width: 50%;
    }

    .news__slide--no-image & {
        flex: 0 0 100%;
        width: 100%;
        
        @media screen and (min-width: $lg + 1){
            flex: 0 0 75%;
            width: 75%;
        }
        
    }
}

.news__media {
    flex: 0 0 100%;
    width: 100%;
    margin-right: $gutter;
    margin-bottom: $gutter;
    aspect-ratio: 16/9;


    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
    }

    @media screen and (min-width: $sm + 1){
        margin-bottom: 0;
        flex: 0 0 30%;
        width: 30%;
    }
}

.news__title {
    @include fs-xl;
    margin-bottom: 1em;

    .news__slide--no-image & {

        @media screen and (min-width: $lg + 1){
            @include fs-2xl;
            margin-bottom: .5em;
        }
        
    }
}

.news__details {


    p {

    }
}

.news__cta {
    @include fs-lg;
}

