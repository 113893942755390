.cky-consent-bar{
    background-color: black !important;
    border-color: black !important;
    .cky-title, .cky-notice-des{
        color:white !important;
    }
}
.cky-btn {

    color: $white !important;

    &.cky-btn-customize {
        background: $purple !important;
        border-color: $purple !important;
    }

    &.cky-btn-preferences {
        background: $purple !important;
        border-color: $purple !important;
    }
    
    &.cky-btn-reject {
        background: $red !important;
        border-color: $red !important;
    }
    
    &.cky-btn-accept {
        background: $green !important;
        border-color: $green !important;
    }
}

.cky-show-desc-btn {
    color: $purple !important;
}
.cky-always-active {
    color: $green !important;
}
.cky-btn-revisit-wrapper{
    display: none !important;
}