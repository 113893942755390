.topbar {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: space-between;
    top: 0;
    left: 0;
    width: 100%;
    padding: $gutter;
    background-color: var(--current-bg-color);
    z-index: 100;
    @include transition;

    // .nav-is-open & {
    //     background-color: transparent;
    // }

    @media screen and (min-width: $lg + 1){
        padding: $gutter*2;
        background-color: transparent;

        &--is-scrolled {
            background-color: var(--current-bg-color);
        }

    }
}

.logo--topbar {
	display: flex;
	align-items: center;
    margin-right: $gutter*2;
	
	a {
		display: flex;
		//color: white;
		text-decoration: none;
		font-size: 40px;
        width: 8ch;
		font-variation-settings: "wght" 950, "wdth" 10, "ital" 0;

        span {
            --wght: 950;
            --wdth: 10;
            --ital: 0;
            font-variation-settings: "wght" var(--wght), "wdth" var(--wdth), "ital" var(--ital);
            
            &:nth-child(4){
                margin-right: .1em;
            }
        }
	}

}

.topbar__page-name {
    display: none;
    margin-left: 1em;
    --wght: 500;
    --wdth: 50;
    --ital: 0;
    // font-variation-settings: "wght" var(--wght), "wdth" var(--wdth), "ital" var(--ital);
    @include fs-sm;
    font-variation-settings: unset;
    //color: white;
    overflow: hidden;
    @include transition;

    span {
        display: block;
        white-space: nowrap;
        //transform: translate3d(0, 100%, 0);
    }

    .nav-is-open & {
        opacity: 0;
        visibility: hidden;
    }

    @media screen and (min-width: $lg + 1){
        display: inline-block;
    }

}

.topbar--page-nav {
    // flex: 1 1 100%;
    display: none;
    flex: auto;
    padding: 5px 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    

    span {
        @include fs-md;
        @include transition;

        .nav-is-open & {
            opacity: 0;
            visibility: hidden;
        }
    }


    @media screen and (min-width: $lg + 1){
        display: flex;
    }
}

.page-nav {
    @include clean-list;
    display: flex;
    @include fs-md;
    //@include transition;

    // .nav-is-open & {
    //     opacity: 0;
    //     visibility: hidden;
    // }
}

.page-nav__item {
    
    margin-right: 1em;

    // &:last-child {
    //     margin-right: 0;
    // }
}

.page-nav__link {
    position: relative;
    text-decoration: none;
    //color: $white;
    opacity: .5;
    text-transform: capitalize;
    @include transition;

    &:after {
        content: "";
        position: absolute;
        display: block;
        bottom: -.2em;
        left: 0;
        height: 1px;
        width: 100%;
        transform-origin: left center;
        transform: scaleX(0) translate3d(0, 0, 0);
        background-color: var(--current-txt-color);
        @include transition;
    }

    &--is-active,
    &:hover {
        opacity: 1;

        &:after {
            transform: scaleX(1) translate3d(0, 0, 0);
        }
    }

    // &:hover {

    //     &:after {
    //         transform: scaleX(1) translate3d(0, 0, 0);
    //     }
        
    // }
    
}

.mobile-page-nav {
    display: flex;
    padding: $gutter 0;
    width: 100%;
    max-width: 100%;
    overflow: hidden;
    min-height: 3.2em;

    // > * {
    //     transform: translateZ(0px);
    // }

    @media screen and (min-width: $lg + 1){
        display: none;
    }
}