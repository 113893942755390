.block--see-more {
    padding: $gutter*4 $gutter;
    background-color: var(--current-bg-color, $white);
    color: var(--current-txt-color, $purple);

    @media screen and (min-width: $lg + 1){
        padding: $space-3xl $gutter*2;
    }
}

.block--see-more__title {
    --wght: 500;
    --wdth: 0;
    @include fs-3xl;
    text-transform: uppercase;
    font-variation-settings: "wght" var(--wght),"wdth" var(--wdth),"ital" 0;
    text-align: center;
    line-height: .75;
    margin-bottom: .5em
}

.see-more-list {
    margin-bottom: 0!important;
}

.see-more-item {
    @include fs-lg;
    border-bottom: 1px solid rgba($purple, .6);

    &:last-child {
        border: none;
    }
}

.our-sevices-link {
    position: relative;
    display: flex;
    justify-content: space-between;
    padding: 1em 0;
    text-decoration: none;
    z-index: 5;
    @include transition;

    &:before {
        content: "";
        position: absolute;
        display: block;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: $purple;
        transform-origin: center left;
        opacity: 0;
        transform: scalex(0.25);
        z-index: -1;
        @include transition;
    }

    &:hover {
        padding: 1em 1em;
        color: $white;

        &:before {
            opacity: 1;
            transform: scalex(1);
        }
        
    }
}