@font-face {
  font-family: "Baikal";
  src: url("../fonts/baikal.woff2") format("woff2"),
       url("../fonts/baikal.ttf") format("truetype-variations");
}

* {
    box-sizing: border-box;
}

html {
    font-size: 62.5%;
}

body {
    --current-bg-color: $black;
    --current-txt-color: $white;
    font-family: "Baikal";
    // font-family: $font--primary;
    background-color: $black;
    background-color: var(--current-bg-color, $black);
    color: $white;
    color: var(--current-txt-color, $white);
    @include transition;

    &.nav-is-open {
        overflow: hidden;
    }
}

h1, h2, h3, h4, h5, h6 {
    font-weight: normal;
    margin: 0;
}

p {
    @include fs-md;

    &:first-child {
        margin-top: 0
    }

    .big-p & {
        @include fs-lg;
    }

    .block--chapo & {
        @include fs-xl--no-clamp;
    }

    .block--title & {
        @include fs-2xl--no-clamp;
    }
}

.content-title {
    grid-column: 1/span 6;
    margin-bottom: 1em
}

.content-title--h1 {
    @include fs-2xl--no-clamp;
}

.content-title--h2 {
    @include fs-lg;
    text-transform: uppercase;
}

.content-title--h3 {
    @include fs-md;
    text-transform: uppercase;
    opacity: .6;
}

strong,
b {
    font-weight: 600;
    font-variation-settings: "wght" 600, "wdth" 100,"ital" 0;

    em {
        font-style: italic;
        font-variation-settings: "wght" 600, "wdth" 100,"ital" 12;
    }
}

em {
    font-style: italic;
    font-variation-settings: "wght" 400, "wdth" 100,"ital" 12;

    strong, 
    b {
        font-variation-settings: "wght" 600, "wdth" 100,"ital" 12;
    } 
}

// .content--title--h4 {

// } 

