.popup-form {
    height: 100dvh;
    position: fixed;
    top: 0;
    right: -60vw;
    width: 60vw;
    background-color: $black;
    z-index: 100; 
    padding: 60px 30px 30px 30px;
    overflow: hidden;
    transition: all 0.3s;
    border-left: 1px solid $white;
    &.open {
        transition: all 0.3s;
        right: 0;
    }

    .spacer {
        width: 100%;
        height: 30px;
    }
    .spacer-xl {
        width: 100%;
        height: 100px;
    }

    .gform_heading {
        display: none !important;
    }
    .gfield_required {
        display: none !important;
    }
    .heure-container .ginput_complex {
        flex-flow: initial !important;
        align-items: center !important;

        .ginput_container_time {
            min-width: initial !important;
            max-width: initial !important;
            flex-basis: initial !important;
        }
    }
    .gform_confirmation_message {
        font-size: 40px !important;
        font-weight: 500 !important;
        width: 100%;
        margin-bottom: 15px !important;
        @media screen and (max-width: $lg) {
            font-size: 30px !important;
        }
    }
    .gfield_description.instruction  {
        display: none !important;
    }
    fieldset.handicap-container {
        width: 100% !important;
        grid-column: 100% !important;
        display: flex !important;
        align-items: center !important;
        gap: 20px;
       

        legend {
            color: $white;
            width: 100% !important;
            text-transform: lowercase;
            opacity: 1 !important;
            font-size: 18px !important;
            &::first-letter {
                text-transform: uppercase;
            }
            @media screen and (max-width: $lg) {
                width: 100%;
                margin-bottom: 10px;
            }
        }
        .ginput_container_radio {
           
            width: 100% !important;
            .gfield_radio {
                display: flex !important;
                align-items: center !important;
                .gchoice {
                    display: flex !important;
                    align-items: center !important;
                    
                    .gfield-choice-input {
                        opacity: 0 !important;
                        &+label {
                            max-width: 100% !important;
                        }
                    }
                    label {
                        display: flex !important;
                        align-items: center !important;
                        font-size: 20px !important;
                        &::before {
                            height: 35px !important;
                            width: 35px !important;
                            padding: 0 !important;
                          
                        }
                    }
                }
            }
        }
    }
    .handicap-other {
        label {
            font-size: 18px !important;
            margin-bottom: 0 !important;
        }
    }
    .adresse-postal-container {
        .ginput_full {
            flex: 0 0 50% !important;
        }
    }
    @media screen and (max-width: $lg) {
        .adresse-postal-container {
            .ginput_full {
                flex: 1 !important;
            }
        }
    }


    @media screen and (max-width: $lg) {
        width: 100vw;
        right: -100vw;
        border-left: none;
    }

    svg {
        transform: translateY(-30px);
        cursor: pointer;
    }

    .wrapper {
        overflow-y: scroll;
        height: 100%;
        width: 100%;

        &::-webkit-scrollbar {
            display: none;
        }
    }

    h1 {
        font-size: clamp(160px,14.645vw,350px);
        font-weight: 500;
        text-transform: uppercase;
        line-height: 0.8;
        text-align: center;
        margin-bottom: 100px;
        margin-top: 100px;
        --wght: 500;
        --wdth: 0;
        font-variation-settings: "wght" var(--wght),"wdth" var(--wdth),"ital" 0;
        @media screen and (max-width: $lg) {
            margin-top: 0;
        }
        
    }
    h4 {
        margin-bottom: 100px;
        font-size: 25px;
        line-height: 32px;
        font-weight: 500;
        text-align: start;
        width: 100%;
    }
    p {
        font-size: 20px;
        font-weight: 400;
        text-align: start;
        margin-bottom: 100px;
        width: 100%;
    }


    label , .gfield_label {
        font-size: 40px !important;
        font-weight: 500 !important;
        width: 100%;
        margin-bottom: 15px !important;
        @media screen and (max-width: $lg) {
            font-size: 30px !important;
        }
    }
   

    input , select {
        font-size: 30px !important;
        font-weight: 500 !important;
        width: 100% !important;
        color: $white;
        outline: none;
        @media screen and (max-width: $lg) {
            font-size: 20px !important;
        }
    }
    .accompagnant-info  {
        font-size: 15px !important;
        font-weight: 500 !important;
        color: $white;
        opacity: .5;
        text-transform: uppercase !important;
    }

    textarea {
        font-size: 30px !important;
        font-weight: 500 !important;
        color: $white !important;
        @media screen and (max-width: $lg) {
            font-size: 20px !important;
        }
    }

        .input-wrapper {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: 1fr;
            gap: 15px;

            @media screen and (max-width: $lg) {
                grid-template-columns: 1fr;
            }
        }
       

        .handicap-list {
            display: flex;
            flex-direction: column;
            gap: 15px;
            @media screen and (max-width: $lg) {
                gap: 30px;
            }

            .handicap {
                display: flex;
                align-items: center;
                width: 100%;
                @media screen and (max-width: $lg) {
                    flex-direction: column;
                }

                .handicap-name {
                    color: $white;
                    width: 50%;
                    text-transform: lowercase;
                    margin-right: 30px;
                    opacity: 1;
                    font-size: 18px;
                    &::first-letter {
                        text-transform: uppercase;
                    }
                    @media screen and (max-width: $lg) {
                        width: 100%;
                        margin-bottom: 10px;
                        margin-right: 0;
                    }
                }

                
            }
        }
    

    input[type=submit] {
        width: 100% !important;
        background-color: transparent;
        outline: none;
        cursor: pointer;
        color: $white;
        font-size: 20px !important;
        font-weight: 500 !important;
        border-radius: 20px;
        border: 1px solid $white;
        height: 41px !important;
        margin-bottom: 100px !important;
        transition: all 0.5s;
        &:hover {
            color: $black;
            background-color: $white;
            transition: all 0.5s;
        }
    }

}