.screen-contact {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: hidden;
}

.screen-contact__layers {
    position: relative;
    flex: 1 1 100%;
}

.screen-contact__layer {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.screen-contact__layer--back {

}

.screen-contact__layer--front {

}

.screen-contact__title {
    --wght: 500;
    --wdth: 0;
    @include fs-big;
    text-align: center;
    text-transform: uppercase;
    line-height: .75;
    font-variation-settings: "wght" var(--wght),"wdth" var(--wdth),"ital" 0;
}

.screen-contact__cta {
    text-align: center;
    .btn {
        margin-right: .25em;
        background-color: $purple;
        padding: .5em 1em;
        @include fs-sm;

        &:last-child {
            margin-right: 1em;
        }

        @media screen and (min-width: $lg + 1){
            margin-right: 1em;
            padding: .5em 3em;
            @include fs-md;
        }
    }
}

.screen-contact__infos {
    display: flex;
    flex-direction: column;
    @include fs-md;
    padding-bottom: $gutter;

    span {
        margin-bottom: .5em;
    }

    a {
        text-decoration: none;
    }

}