.footer {}

.footer__container {
    background-color: $black;
    color: white;
    padding: $gutter*2 $gutter $gutter;
    // border-top-left-radius: 10px;
    // border-top-right-radius: 10px;

    @media screen and (min-width: $lg + 1){
        padding: $gutter*3 $gutter*2 $gutter;
    }
}

.footer__item {
    padding-top: $gutter;

    &:first-child {
        padding-top: 0;
        padding-bottom: $gutter;
        border: none;
    }

    @media screen and (min-width: $lg + 1){
        border-top: 1px solid currentColor;
    }
}

.footer-title {

    .footer__item:nth-child(2) & {
        margin-bottom: 33px;

        @media screen and (min-width: $lg + 1){
            margin-bottom: 0;
        }
    }
}

.footer__bottom {
    padding-top: $gutter*4;
    text-align: center;

    @media screen and (min-width: $lg + 1){
        padding-top: 25vh;
    }
}


.logo--footer {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    z-index: 10;
    overflow: hidden;
    user-select: none;

    img {
        position: absolute;
        display: block;
        display: none;
        left: 0;
        //mix-blend-mode: multiply;
        width: 100%;
        height: calc(100% + 20px);
        object-fit: cover;
        top: -10px;
        pointer-events: none;
        z-index: 3;
        filter: brightness(150%);
    }

    a {
        display: flex;
        width: 100%;
        color: white;
        text-decoration: none;
        font-size: 55px;
        font-size: 25vw;
        line-height: .8;
        font-variation-settings: "wght" 950, "wdth" 10, "ital" 0;
        background-color: black;
        mix-blend-mode: darken;
        z-index: 6;
    }

    span {
        --wght: 950;
        --wdth: 10;
        --ital: 0;
        font-variation-settings: "wght" var(--wght), "wdth" var(--wdth), "ital" var(--ital);

        &:nth-child(4) {
            margin-right: .1em;
        }
    }

    @media screen and (min-width: $lg + 1){
        a {
            font-size: 26.6vw;
        }
    }
}

.footer__footer {
    a {
        text-decoration: none;
        color: white;
    }
}

.footer__strapline {
    @include fs-sm;
}

.footer__copyright {
    @include fs-xs;
}