.filter-bar {
    position: sticky;
    width: calc(100% + #{$gutter * 2});
    top: 86px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: $white;
    margin-left: - $gutter;
    padding: 0 $gutter;
    @include fs-md;
    z-index: 10;
    background-color: var(--current-bg-color);
    transition: all .5s ease-out;

    &--is-scrolled {
        transform: translate3d(0, -100%, 0);
    }

    @media screen and (min-width: $lg + 1){
        width: calc(100% + #{$gutter * 4});
        margin-left: - $gutter * 2;
        padding: 0 $gutter*2;
        top: 126px;
        flex-direction: row;
    }

}

.filter-events {
    position: relative;
    border-bottom: 1px solid rgba($white, .6);

    @media screen and (min-width: $lg + 1){
        padding: 0;
        border: none;
    } 
}

.search-events {
    position: relative;
    align-items: center;
    border-bottom: 1px solid rgba($white, .6);
    justify-content: space-between;
    z-index: 150;

    input {
        text-transform: uppercase;
        @include fs-md;
    }
}

.topbar--search {
    //display: none;
    position:relative;
    display:flex;
    @media screen and (min-width: $lg) and (max-width: 1200px){
        position: absolute;
        right: 0px;
        top: 80px;
        margin-right: 40px;
    }
    @media screen and (max-width: $sm){
        display:none;
    }
    }

.nav--main__search {
    display: flex;
    margin-bottom: $gutter*4;

    @media screen and (min-width: $sm + 1){
        display: none;
    }
}

.search-events__input-wrapper {
    position: relative;
    width: 100%;

    &:after {
        content: "";
        position: absolute;
        display: block;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 1px;
        background-color: $white;
        transform-origin: left center;
        transform: scale(0) translate3d(0, 0, 0);
        @include transition;
    }

    &.is-focused,
    &:hover {

        &:after {
            transform: scale(1) translate3d(0, 0, 0);
        }
    }

    @media screen and (min-width: $lg + 1){
        border: none;
        justify-content: flex-start;
    }
}

.search-events__input {
    background-color: transparent;
    border: none;
    padding: $gutter 0;
    color: $white;
    width: 100%;
    box-sizing: content-box;
    @include transition;

    &::placeholder {
        color: $white;
        opacity: .6;
        text-transform: uppercase;
    }

    &:focus {
        outline: none;
        width: 100%;
    }

    @media screen and (min-width: $lg + 1){
        padding: $gutter 30px $gutter 0;
        width: 143px;

        &:focus {
            width: 20ch;
        }
    }
}

.search-events__submit {
    position: absolute;
    top: calc(50% - 10px);
    right: 0;
    background-color: transparent;
    border: none;
    padding: 0;
    flex: 0 0 20px;
    max-width: 20px;
    height: 20px;
    cursor: pointer;
}

.search-events__label {
    display: none;
}

.search-events__icon {
    display: block;
}

.search-events__svg {
    width: 100%;
}

.search-events__results {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    background-color: black;
    border: 1px solid white;
    max-height: 400px;
    overflow: auto;
    padding: 20px;

    li {
        margin-bottom: $gutter*2;
       .search-result{
        margin: 0;
        &.date{
            margin-top: 1rem;
        }
       } 
        &:last-child {
            margin-bottom: $gutter;
        }
    }

    a {
        @include fs-xl--no-clamp;
    }

    .topbar--search & {
        right: 0;
        left: unset;
        width: 400px;
        background-color: var(--current-bg-color);
        color: var(--current-txt-color);
    }
}

.event-cat-trigger {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    background-color: transparent;
    border: none;
    color: $white;
    text-transform: uppercase;
    padding: $gutter 0;
    cursor: pointer;

    &:before {
        content: 'X';
        position: absolute;
        right: 0;
        top: 1em;
        opacity: 0;
        visibility: hidden;
        color: $white;
        @include transition;
        transition-delay: 0;
    }

    &--is-open {
        &:before {
            opacity: 1;
            visibility: visible;
            transition-delay: .2s;
        }
    }

    @media screen and (min-width: $lg + 1){
        justify-content: flex-start;
        width: auto;

        &:after {
            content: "";
            position: absolute;
            display: block;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 1px;
            background-color: $white;
            transform-origin: left center;
            transform: scale(0) translate3d(0, 0, 0);
            @include transition;
        }

        &:hover,
        &--is-open {
            &:after {
                transform: scale(1) translate3d(0, 0, 0);
            }
        }
    }
}

.event-cat-trigger__label {
    margin-right: 1em;
}

.event-cat-trigger__current-cat {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    @include transition;

    &:before {
        content: "";
        display: block;
        width: 0;
        height: 0;
        margin-left: .5em;
        margin-right: 0;
        border-top: .33em solid transparent;
        border-left: .66em solid white;
        border-bottom: .33em solid transparent;
        transform: rotate(90deg);
    }

    .event-cat-trigger--is-open & {
        opacity: 0;
        visibility: hidden;
    }

    @media screen and (min-width: $lg + 1){
        flex-direction: row;

        &:before {
            margin-left: 0;
            margin-right: 1em;
            transform: none;
        }
    }
}

.event-cat-list {
    position: relative;
    top: 100%;
    width: 100%;
    height: 0;
    overflow: hidden;
    text-transform: uppercase;
    background-color: var(--current-bg-color);
    //padding-left: 1em;

    &:after {
        content: "";
        position: absolute;
        display: block;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 1px;
        background-color: $white;
        background-color: var(--current-text-color, $white);
        opacity: 0;
        transition: all .3s ease-in-out;
        //transition-delay: .2s;
    }

    ul {
        opacity: 0;
        padding: 1em 0;
    }

.archive & {
    ul {
        display: flex;
        flex-wrap: wrap;

        li {
            padding-right: 1em;
        }
    }
}

    li {

    }

    a {
        position: relative;
        display: block;
        padding: $gutter/2 0;
        width: 100%;
        text-decoration: none;
        @include transition;

        &:before {
            content: "";
            position: absolute;
            display: block;
            left: 0;
            top: calc(50% - .33em);
            width: 0;
            height: 0;
            border-top: .33em solid transparent;
            border-left: .66em solid white;
            border-bottom: .33em solid transparent;
            opacity: 0;
            visibility: hidden;
            @include transition;
        }

        &:hover,
        &.is-active {
            padding-left: 1em;

            &:before {
                opacity: 1;
                visibility: visible;
            }
        }
    }

    @media screen and (min-width: $lg + 1){
        width: calc(100vw - #{$gutter * 4});
        position: absolute;

        // &.event-cat-list--is-open:after {
        //     display: block;
        // } 

        &.event-cat-list--is-open {
            &:after {
                opacity: 1;
            }
        }

        a {
            display: unset;
            padding: 0;
        }
    }
}

.is-search-form {
    position: relative;

    &:after {
        content: "";
        position: absolute;
        display: block;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 1px;
        background-color: #FFF;
        transform-origin: left center;
        transform: scale(0) translate3d(0,0,0);
        @include transition;
    }

    &.processing {
        &:after {
            animation: blinker .5s ease-in-out infinite;
        }
    }

    &.is-focused {
        &:after {
            transform: scale(1) translate3d(0,0,0);
        }
    }

    button.is-search-submit {
        position: absolute;
        top: 0;
        right: 0;
        border: none;
        background-color: transparent;
        cursor: pointer;
        height: 100%; 

        .is-screen-reader-text {
            display: none;
        }

        path {
            fill: var(--current-txt-color);
        }
    }

    input {
        padding: 20px 30px 20px 0;
        width: 175px;
        border-bottom: none;
        @include transition;

        &::placeholder {
            color: var(--current-txt-color);
            opacity: .5;
            text-transform: uppercase;
        }

        &:focus {
            width: 20ch;
            outline: none;
        }
    }
}

@keyframes blinker {
    50% {
      opacity: 0;
    }
  }

.search-events label > span {
    display: none;
}

.is-ajax-search-result {
    position: absolute;
    top: calc(100% - 1px);
    right: 0;
    width: 500px;
    max-width: calc(100vw - 40px);
    padding: 20px;
    background: var(--current-bg-color);
    color: var(--current-txt-color);
    border: 1px solid var(--current-txt-color);
    overflow: auto;
}

.is-title {
    @include fs-xl--no-clamp;
    margin-bottom: .5em;

    a {
        text-decoration: none;
    }
}

.is-ajax-result-description {
    @include fs-sm;
}

.is-ajax-search-post {
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid var(--current-txt-color);

    &:last-child {
        margin-bottom: 0;
        //padding-bottom: 0;
        border-bottom: none;
    }
}