.single-event__header {
    margin-bottom: $gutter*2;
}

.single-event__title {
    @include fs-2xl;
    margin-bottom: 1em;

    &--with-tour {
        margin-bottom: 0;
    }
}

.single-event__tour {
    @include fs-xl--no-clamp;
    margin-bottom: 2em;

    @media screen and (min-width: $lg + 1){
        @include fs-2xl--no-clamp;
    }
}

.single-event__style {
    display: flex;
    flex-direction: column;

    span {
        &:first-child {
            @include fs-sm;
            opacity: .6;
            margin: .5em 0;
        }
    }
}

.single-event__hashtag {
    @include fs-lg;
}

