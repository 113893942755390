.main {
    padding: 0 $gutter;
    
    @media screen and (min-width: $lg + 1){
        padding: 0 $gutter*2;
    }

    &--top-padding {
        padding-top: 25vh;
    }

    &--no-padding {
        padding: 0;
    }
}

.main--agenda {
    padding-bottom: $gutter*3;
    min-height: 100vh;
    .representation--no_results {
        margin-top: 150px;
    }
    // padding-top: 150px;

    // @media screen and (min-width: $lg + 1){
    //     padding-top: 0;
    // }
}