.card-grid {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-column-gap: $gutter;
    grid-row-gap: $gutter*3;

    @media screen and (min-width: $lg + 1){
        grid-template-columns: repeat(2, 1fr);
    }

    @media screen and (min-width: $xl + 1){
        grid-template-columns: repeat(3, 1fr);
    }

    @media screen and (min-width: $xxl + 1){
        grid-template-columns: repeat(4, 1fr);
    }
}