ul {
    @include clean-list;
    @include fs-md;

    .block & {
        margin-bottom: 1em;
    }
    //line-height: 1.33em;

    // a {
    //     position: relative;
    //     display: inline-block;
    //     text-decoration: none;
    //     overflow: hidden;

    //     &:before,
    //     &:after {
    //         content: "";
    //         position: absolute;
    //         display: block;
    //         left: 0;
    //         bottom: 0;
    //         width: 100%;
    //         height: .075em;
    //         border-radius: 10px;
            
    //     }

    //     &:before {
    //         background-color: $white;
    //     }

    //     &:after {
    //         background: linear-gradient(90deg, rgba(255,0,0,1) 0%, rgba(0,63,255,1) 50%, rgba(243,255,0,1) 100%);
	// 	    transform: translate3d(-100%, 0, 0);
    //         @include transition;
    //     }

    //     &:hover {
    //         &:after {
    //             transform: translate3d(0, 0, 0);
    //         }
    //     }
    // }
}

ol {
    @include fs-md;
}

.list-of-list {
    container-type: inline-size;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    a {

    }

}

.list-of-list__item {
    flex: 0 1 100%;
    display: flex;
    flex-direction: column;
    @include fs-sm;
    line-height: 1.5;
    margin-bottom: $gutter * 2;

    @container (min-width: 600px){
        flex: 0 1 calc(50% - #{$gutter / 2});
    }

    .form-btn-open {
        background-color: transparent;
        border: 2px solid $white;
        border-radius: 100px;
        padding: 0.8em 4em; 
        display: flex;
        justify-content: center;
        align-items: center;
        outline: none;
        cursor: pointer;
        transition: all 0.3s ease-in-out;
        color: $white;
        width: 350px;

        @media screen and (max-width: 400px) {
            width: 100%;
        }

        &:first-of-type {
            margin-bottom: 20px;
        }

        &:hover {
            background-color: $white;
            color: $black;
            transition: all 0.3s ease-in-out;
        }
    }
    
}

.list-of-list__title {
    opacity: .6;
}

.other-dates-title {
    @include fs-sm;
    opacity: .6;
}

.list-of-list__sub-list {

}

.list-of-list__sub-item {

}
