.nav-trigger,
.close-nav {
    display: flex;
    align-items: center;
    border: none;
    margin-left: $gutter*2;
    padding: 0;
    background-color: transparent;
    color: currentColor;
    @include fs-md;
    text-transform: uppercase;
    cursor: pointer;
}

.burger {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 17px;
    height: 14px;
    margin-right: .33em;

    .close-nav & {
        justify-content: center;
    }
}

.burger__bar {
    width: 100%;
    height: 1px;
    background-color: currentColor;

    &--top {

    }

    &--middle {

    }

    &--bottom {

    }

    .close-nav & {
        
        &--top {
            transform: translate3d(0, .5px, 0) rotate(45deg);
        }

        &--bottom {
            transform: translate3d(0, -.5px, 0) rotate(-45deg);
        }
    }
}

.nav-trigger__label {
    @include fs-md;
}

.event__call-back {
    @include fs-sm;
    flex: 0 0 auto;

    @media screen and (min-width: $xxl + 1){
        @include fs-md;
    }
}

.double-buttons {
    display: flex;
}

.double-bouttons__btn {
    display: flex;
    @include transition;
    text-align: center;
    align-items: center;
    justify-content: center;

    &:hover {
        background-color: $white;
        color: var(--current-bg-color, $purple);
    }
} 

.double-bouttons__btn--info {
    padding: .8em 4em;
    border-top: 2px solid var(--current-txt-color, $white);
    border-bottom: 2px solid var(--current-txt-color, $white);
    border-left: 2px solid var(--current-txt-color, $white);
    border-right: 2px dashed var(--current-txt-color, $white);
    border-top-left-radius: 100px;
    border-bottom-left-radius: 100px;
    margin-right: -2px;
    
    .event__call-back--mobile & {
        flex: auto;
    }
}



.double-bouttons__btn--ticket {
    position: relative;
    padding: .8em 2em;
    border-top: 2px solid var(--current-txt-color, $white);
    border-bottom: 2px solid var(--current-txt-color, $white);
    border-right: 2px solid var(--current-txt-color, $white);
    border-left: 2px dashed var(--current-txt-color, $white);
    border-top-right-radius: 100px;
    border-bottom-right-radius: 100px;
    transform-origin: bottom left;

    &:before {
        content: "";
        position: absolute;
        display: block;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: transparent;
        transform-origin: bottom left;
        z-index: -1;

        @include transition;
    }

    &:hover {
        transform: translate3d(0, 0, 0) rotate(12.5deg);

        &:before {
            transform: translate3d(0, 0, 0) rotate(-12.5deg);
        }
    }

    &:active {
        transform: translate3d(.5em, -.25em, 0) rotate(15deg);
    }
}

.btn,
.post-password-form [type="submit"] {
    --left: 0;
    --top: 0;
    position: relative;
    display: inline-block;
    @include fs-md;
    color: $white;
    text-decoration: none;
    text-align: center;
    border: 2px solid $white;
    border-radius: 50px;
    padding: .5em 3em;
    overflow: hidden;
    color: $white;
    background-color: transparent;
    z-index: 1;
    transition: all .3s ease-in-out;
    cursor: pointer;
    //flex-wrap: nowrap;

    &:before {

        content: "";
        position: absolute;
        display: block;
        top: var(--top);
        left: var(--left);
        width: 200%;
        aspect-ratio: 1/1;
        // background-color: $purple;
        background: radial-gradient(circle, rgba(255,0,0,1) 0%, rgba(0,63,255,1) 40%, rgba(243,255,0,1) 70%);
        background-image: radial-gradient(
        circle,
        hsl(262deg 100% 50%) 0%,
        hsl(335deg 100% 36%) 40%,
        hsl(55deg 100% 50%) 70%
        );
        border-radius: 50%;
        z-index: -1;
        //opacity: 0;
        transform: translate3d(-50%, -50%, 0) scale(0);
        transition: transform .5s ease-in-out;
        filter: blur(10px) saturate(150%);
    }

    &:after {

        content: "";
        position: absolute;
        display: block;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        //background-color: $white;
        z-index: -1;
        //opacity: 0;
        //transform: translate3d(0, 0, 0);
        //transition: transform .5s ease-in-out 0s;
        //backdrop-filter: blur(10px);
        //backface-visibility: hidden;
    }

    &:hover {
        //color: $purple;
        border-color: transparent;
        
        &:before {
            opacity: 1;
            transform: translate3d(-50%, -50%, 0) scale(1);
        }

        &:before {
            transition: transform .5s ease-in-out;
        }

        // &:after {
        //     transition: all .5s ease-in-out .025s;
        // }
    }

}

.post-password-form {
    width: 100%; 
    max-width: 500px;
    margin-top: 20px;
    padding: 0 20px;

    [type="password"] {
        margin-top: 10px;
    }

    [type="submit"] {
        margin-top: 10px;

        &:hover {
            background-color: $white;
            color: black;
        }
    }

}

