.block {
    margin-bottom: $gutter;

    &:last-child {
        margin-bottom: 0;
    }

    @media screen and (min-width: $lg + 1){
        margin-bottom: $gutter*2;
    }
}

.block--small-margin {
    margin-bottom: $gutter*2;

    @media screen and (min-width: $lg + 1){
        margin-bottom: $gutter*4;
    }
} 

.block--big-margin {
    margin-bottom: $gutter*4;

    @media screen and (min-width: $lg + 1){
        margin-bottom: $gutter*8;
    }
}

.block--large {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: $gutter;
    grid-row-gap: 0px;

    >div {
        grid-area: 1 / 2 / 2 / 12;
    }
}

.block--small {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: $gutter;
    grid-row-gap: 0px;

    >div {
        grid-area: 1 / 3 / 2 / 11;
    }
}

.block__title {
    text-transform: uppercase;
    @include fs-lg;
    margin-bottom: 2em;
}