.flex-grow-auto {
    flex: 1 0 auto;
    max-width: 100%;
}

.hidden {
    display: none;
}

.flex-center {
    display: flex;
    justify-content: center;
}