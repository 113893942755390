.page-transition {
    position: fixed;
    display: flex;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    opacity: 0;
    visibility: hidden;
    z-index: 150;
    //background-color: black;

}

.page-transition__line {
    flex: 1 1 100%;
    height: 100%;
    transform-origin: center top;
    background-color: var(--current-bg-color);
    background-color: $white;
    background-color: #FFAAC7;
    transform: translate3d(0, 0, 0) scaleY(0);
    transition: background-color .3s ease-in-out;

    // &:nth-child(1) {
    //     background-color: $purple;
    // }

    // &:nth-child(2) {
    //     background-color: $light-purple;
    // }

    // &:nth-child(3) {
    //     background-color: $corail;
    // }

    // &:nth-child(4) {
    //     background-color: $red;
    // }

    // &:nth-child(5) {
    //     background-color: $yellow;
    // }

    // &:nth-child(6) {
    //     background-color: $green;
    // }

    // &:nth-child(7) {
    //     background-color: $fluo-green;
    // }

    // &:nth-child(8) {
    //     background-color: $forest-green;
    // }

    // &:nth-child(9) {
    //     background-color: $night-blue;
    // }

    // &:nth-child(10) {
    //     background-color: $cream;
    // }
}
