.warning-message {
    position: relative;
    color: $red;
    padding: 2em 0;
    overflow: hidden;

    &:before,
    &:after {
        content: "******************************************************************************************************************************************************************************************";
        position: absolute;
        display: block;
        font-size: inherit;
        left: 0;
    }

    &:before {
        top: 0;
    }

    &:after {
        bottom: -0.35em;
    }
}